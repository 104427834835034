import { userAxios } from '../index'

export const citiesList = async pagination => {
  try {
    return await userAxios.get(`states/cities/all/${pagination}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export const citiesCreate = async data => {
  try {
    return await userAxios.post(`states/cities`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}
/**
 * It's a function that returns a promise that resolves to the data of a GET request to the URL
 * /cities?page=
 * @param page - The page number to fetch.
 * @returns An array of objects.
 */
export const citiesFilterPagination = async (pagination, page) => {
  try {
    return await userAxios
      .get(`states/cities/all/${pagination}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}
/**
 * It takes a data object, sends it to the server, and returns the response.
 * @param data - {
 * @returns The data that is being returned is an array of objects.
 */
export const citiesFilterFilters = async data => {
  try {
    return await userAxios.post('states/cities/search', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}
/**
 * It takes a page number and a data object as arguments, and then it makes a post request to the
 * server with the page number and data object as parameters.
 * @param page - the page number
 * @param data - {
 * @returns The data is being returned as an object with the following structure:
 * {
 *     "current_page": 1,
 *     "data": [
 *         {
 *             "id": 1,
 *             "name": "City 1",
 *             "country_id": 1,
 *             "country": {
 *                 "id": 1,
 *                 "name": "Country 1"
 */
export const citiesFilterFiltersPagination = async (page, data) => {
  try {
    return await userAxios.post(`/cities-search?page=${page}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const citiesUpdate = async (id, data) => {
  try {
    return await userAxios.put(`states/cities/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  citiesList,
  citiesCreate,
  citiesFilterPagination,
  citiesFilterFilters,
  citiesFilterFiltersPagination,
  citiesUpdate,
}
