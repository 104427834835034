<template>
  <div>
    <h4 class="m-8">{{ userData.name }} {{ userData.lastname }}</h4>
    <!-- Observador del Formulario -->
    <ValidationObserver ref="formObserver" v-slot="{ invalid, handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <!-- Primera Fila -->
        <b-row>
          <!-- Campo Name -->
          <b-col md="4">
            <ValidationProvider name="Name" rules="required" v-slot="{ errors, classes }">
              <b-form-group
                label="Name"
                label-for="name"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Name is required.">
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :class="classes"
                  placeholder="Enter name"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Last Name -->
          <b-col md="4">
            <ValidationProvider
              name="Last Name"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Last Name"
                label-for="lastname"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Last Name is required.">
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  :class="classes"
                  placeholder="Enter last name"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Email -->
          <b-col md="4">
            <ValidationProvider
              name="Email"
              rules="required|email"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Email"
                label-for="email"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Email is required and must be valid.">
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :class="classes"
                  placeholder="Enter email"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- Segunda Fila -->
        <b-row>
          <!-- Campo Phone -->
          <b-col md="4">
            <ValidationProvider
              name="Phone"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Phone"
                label-for="phone"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Phone is required.">
                <b-form-input
                  id="phone"
                  v-model="form.phone"
                  :class="classes"
                  placeholder="Enter phone number"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Date of Birth -->
          <b-col md="4">
            <ValidationProvider
              name="Date of Birth"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Date of Birth"
                label-for="date_birth"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Date of Birth is required.">
                <flat-pickr
                  id="date_birth"
                  v-model="form.date_birth"
                  :config="config"></flat-pickr>
                <b-form-invalid-feedback v-if="errors.length">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Gender -->
          <b-col md="4">
            <ValidationProvider
              name="Gender"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Gender"
                label-for="gender"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Gender is required.">
                <b-form-select
                  id="gender"
                  v-model="form.gender"
                  :class="classes"
                  :options="genderOptions"></b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- Tercera Fila -->
        <b-row>
          <!-- Campo Reference -->
          <b-col md="4">
            <ValidationProvider
              name="Reference"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Reference"
                label-for="reference"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Reference is required.">
                <b-form-select
                  id="reference"
                  v-model="form.reference"
                  :class="classes"
                  :options="referenceOptions"></b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Address -->
          <b-col md="4">
            <ValidationProvider
              name="Address"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Address"
                label-for="address"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Address is required.">
                <b-form-input
                  id="address"
                  v-model="form.address"
                  :class="classes"
                  placeholder="Enter address"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo City -->
          <b-col md="4">
            <ValidationProvider name="City" rules="required" v-slot="{ errors, classes }">
              <b-form-group
                label="City"
                label-for="city"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="City is required.">
                <b-form-input
                  id="city"
                  v-model="form.city"
                  :class="classes"
                  @input="searchCities"
                  placeholder="Enter city name"></b-form-input>
                <b-form-select
                  v-if="cityOptions.length"
                  v-model="selectedCity"
                  :options="cityOptions"
                  @change="onCitySelect"></b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- Cuarta Fila -->
        <b-row>
          <!-- Campo Zip Code -->
          <b-col md="4">
            <ValidationProvider
              name="Zip Code"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Zip Code"
                label-for="zip_code"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Zip Code is required.">
                <b-form-input
                  id="zip_code"
                  v-model="form.zip_code"
                  :class="classes"
                  placeholder="Enter zip code"></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Campo Status -->
          <b-col md="4">
            <ValidationProvider
              name="Status"
              rules="required"
              v-slot="{ errors, classes }">
              <b-form-group
                label="Status"
                label-for="status"
                :state="errors.length === 0 ? null : false"
                invalid-feedback="Status is required.">
                <b-form-select
                  id="status"
                  v-model="form.status"
                  :class="classes"
                  :options="statusOptions"></b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <h6 class="m-6 text-danger">
          All data will be reviewed and should be completed with true information.
        </h6>

        <!-- Botón de Envío -->
        <b-button type="submit" :disabled="invalid" variant="primary"
          >Save Changes</b-button
        >
      </b-form>
    </ValidationObserver>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import ToastNotification from '@/components/ToastNotification'
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axiosC from '@/core/services/api/admin/cities'
import axiosP from '@/core/services/api/patient'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// Reglas de validación
extend('required', required)
extend('email', email)

export default {
  components: { flatPickr, ValidationObserver, ValidationProvider, ToastNotification },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        date_birth: '',
        gender: '',
        reference: '',
        address: '',
        city: '',
        zip_code: '',
        status: '',
        cities_id: '',
      },
      cityOptions: [],
      selectedCity: null,
      referenceOptions: [],
      statusOptions: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
      config: {
        dateFormat: 'm-d-Y',
        allowInput: true,
      },
      boolFrontDesk: false,
      setTimeoutBuscador: '',
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('userData')).roles[0].id === 5) {
      this.boolFrontDesk = true
    }
    this.initializeForm()
    this.fetchReferences()
  },
  methods: {
    initializeForm() {
      // Inicializa el formulario con los datos del usuario
      Object.assign(this.form, this.userData)
      this.form = {
        ...this.form,
        city: this.userData.cities?.name || '',
        zip_code: this.userData?.zip_codes_id || '',
        reference: this.userData?.reference.id || '',
      }
    },
    searchCities() {
      if (this.form.city.length) {
        clearTimeout(this.setTimeoutBuscador)
        this.setTimeoutBuscador = setTimeout(() => {
          const datos = { name: this.form.city }
          axiosC
            .citiesFilterFilters(datos)
            .then(({ data }) => {
              this.cityOptions = data.map(city => ({
                text: city.name,
                value: city.id,
              }))
            })
            .catch(() => {
              // Manejo de errores
              console.error('Error fetching cities')
            })
        }, 350)
      } else {
        this.cityOptions = []
      }
    },
    onCitySelect() {
      this.form.cities_id = this.selectedCity
      this.form.city = this.selectedCity
    },
    fetchReferences() {
      // Llama al servicio para obtener referencias
      axiosP
        .referencePagination(10, 1)
        .then(({ data }) => {
          this.referenceOptions = data.data.map(reference => ({
            text: reference.name,
            value: reference.id,
          }))
        })
        .catch(() => {
          // Manejo de errores
          console.error('Error fetching references')
        })
    },
    onSubmit() {
      if (typeof this.form.city === 'string') {
        this.form = {
          ...this.form,
          reference: this.form.reference,
          city: this.userData.cities?.id || '',
          cities_id: this.userData.cities?.id || '',
        }
      }
      // Maneja el envío del formulario
      this.$refs.formObserver.validate().then(valid => {
        if (valid) {
          axiosP
            .patientUpdate(this.userData.id, this.form)
            .then(({ status }) => {
              if (status === 'ERROR') {
                this.$refs.toast.danger('Error updating user')
              } else {
                this.$refs.toast.success('User updated successfully')
                this.form = {
                  ...this.form,
                  city: this.userData.cities?.name || '',
                }
              }
            })
            .catch(() => {
              this.$refs.toast.danger('Error updating user')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.media-body {
  align-self: center;
}
.is-invalid {
  border-color: red;
}
</style>
